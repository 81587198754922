import { Timestamp } from '@firebase/firestore-types';

import { Coordinates } from '.';

export enum IncidentSourceType {
  TORONTO_POLICE = 'torontoPoliceService',
}

export enum IncidentType {
  STABBING = 'stabbing',
  SEE_AMBULANCE = 'see ambulance',
  BREAK_AND_ENTER = 'break and enter',
  ASSUALT_JUST_OCCURED = 'assualt',
  DEFAULT = '',
}

export interface TorontoPoliceIncident {
  sourceId: string;
}

export interface Incident<T> {
  id: string;
  source: IncidentSourceType;
  type: IncidentType;
  coordinates: Coordinates;
  name: string;
  location: string;
  date: Timestamp;
  data: T;
}
